<template>
  <div class="photo--preview">
    <div class="first--col">
      <picture>
        <source :srcset="photoSource" type="image/webp" />
        <source :srcset="getFallbackImage(photoSource)" type="image/jpeg" />
        <img
          :src="getFallbackImage(photoSource)"
          width="100%"
          height="auto"
          class="photo--image input--margin"
        />
      </picture>
    </div>
    <div class="second--col">
      <div>{{ photo.fileName }}</div>
      <div class="use--primary" v-if="!isFloorPlan">
        <div class="check" :class="{ checked: photo.isPrimary }" @click="togglePrimary"></div>
        <input
          :id="`set-primary-${photo.uuid}`"
          :checked="photo.isPrimary"
          type="radio"
          class="radio--input"
          @click="togglePrimary"
        />
        <label class="radio--label" :for="`set-primary-${photo.uuid}`">{{
          $t('addListing.primaryPhoto')
        }}</label>
      </div>
    </div>
    <div class="third--col">
      <img src="@/assets/img/icons/trash.svg" @click="removePhoto" class="trash--button" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'photo-preview',
  props: ['index', 'photo', 'length', 'isFloorPlan'],
  mixins: [HelperMixin],
  computed: {
    ...mapState({
      baseUrl: state => state.global.baseUrl,
    }),
    photoSource() {
      if (this.photo.src.search('/storage/') !== -1) {
        return this.baseUrl + this.photo.src;
      } else {
        return this.photo.src;
      }
    },
  },
  methods: {
    togglePrimary() {
      this.$store.commit('v2/listingForm/sect1/media/TOGGLE_PRIMARY_PHOTO', this.index);
    },
    removePhoto() {
      if (this.isFloorPlan) {
        this.$store.commit('v2/listingForm/sect1/media/REMOVE_FLOOR_PLAN', this.index);
      } else {
        this.$store.commit('v2/listingForm/sect1/media/REMOVE_PHOTO', this.index);
      }
    },
  },
};
</script>

<style scoped></style>
